<template>
  <base-section id="home-first">
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <p>
            Ironwood Titian Grandjean est une entreprise qui propose ses services dans les domaines de la construction
            métallique, la serrurerie et la conception sur mesure d’agencement intérieur de véhicules. En amenant de
            l’artisanat dans un domaine plutôt industriel (l’automobile) nous sommes souvent confronté au défis de
            concevoir et réaliser ce que l’industrie ne propose pas. L’écoute, la créativité et la réflexion sont nos
            atouts pour trouver des solutions aux demandes particulières de nos clients.
          </p>
          <p>
            Ces atouts sont également bénéfique pour notre activité dans le bâtiment. Là où la standardisation s’arrête,
            nous trouvons une solution. Nous sommes une petite équipe qui profite de nos formations variées, se qui nous
            permet d’aborder les demandes avec plusieurs points de vue.
            Nous fonctionnons à trois personnes.
          </p>
          <p>
            La partie comptabilité, offre, bureau et dessin technique, est gérée par le patron, Titian Grandjean.
          </p>
          <p>
            Pour la Réalisation et la mise en place nous sommes les trois, avec chacun ses spécialités.
          </p>
          <div class="text-subtitle-1">
            Employées
          </div>
          <div class="row mt-10">
            <div class="col-md-4 text-center">
              Titian Grandjean <br>
              <strong>Patron</strong> <br>
              <small>
                <ul style="list-style-type: none">
                  <li>CFC constructeur métallique</li>
                  <li>Serrurier</li>
                  <li>Formation pose et programmation porte novoferm</li>
                  <li>Formation sur Systême Forster et BMT pour la fabrication et pose de d’élément coupe feu.</li>
                  <li>Papier de Certification soudure MIG/MAG en positions selon exigences Norme ISO 9005.</li>
                  <li>Permis de cariste et télescopique.</li>
                </ul>
              </small>
            </div>
            <div class="col-md-4 text-center">
              Colin Péquignot <br>
              <strong>Ouvrier</strong> <br>
              <small>
                <ul style="list-style-type: none">
                  <li>CFC mécano auto</li>
                  <li>CFC constructeur d’appareils industriel</li>
                  <li>Constructeur métallique</li>
                </ul>
              </small>
            </div>
            <div class="col-md-4 text-center">
              Robin Lopez<br>
              <strong>Ouvrier</strong> <br>
              <small>
                <ul style="list-style-type: none">
                  <li>CFC carrossier</li>
                  <li>Expérimenté en Serrurerie/ Construction métallique</li>
                  <li>Formation complémentaire pour la soudure TIG. (alu, inox et acier)</li>
                  <li>Formation pour de la fabrication de carde vélo en différentes matières.</li>
                </ul>
              </small>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHomeSociety',

    data: () => ({}),
  }
</script>
<style lang="scss" scoped>
blockquote {
  quotes: "“" "”" "‘" "’";
  font-weight: bold;
  display: block;
  margin: 30px 0;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  line-height: 12px;
  text-indent: -0.45em;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &::before {
    content: open-quote;
    text-align: left;
    //left: 0
    left: 0;
    top: 0;
  }

  &::after {
    text-align: right;
    content: close-quote;
    right: 0;
    top: -5px;
  }

  @media all and (max-width: 409px) {
    width: 100%;
    padding: 0 15px;
  }

  &::after, &::before {
    font-size: 32px;
    display: block;
    position: absolute;
  }
}

.signature {
  margin-top: 25px;
  padding-top: 5px;
  border-top: 1px solid #212121;
  display: inline-block;
}
</style>
